import "./App.css";
import { Analytics } from "@vercel/analytics/react";
import React, { Suspense } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import { BrowserRouter as Router } from "react-router-dom";
import { Card } from "react-bootstrap";
import { AllRoutes } from "./routes/routes";
import { LoadingOutlined } from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import SNSButtonGroup from "./components/snsButtonGroup";
import BreadcrumbList from "./components/breadcrumb";

// inject();

function App() {
  return (
    <div className="App">
      <SNSButtonGroup />
      <Router>
        <Header />
        <BreadcrumbList />
        <Card
          className="card-content container"
          // style={{ backgroundColor: "rgb(255, 232, 206)" }}
        >
          <Card.Body>
            <Suspense
              fallback={
                <div className="text-center">
                  <LoadingOutlined />
                </div>
              }
            >
              <AllRoutes />
            </Suspense>
          </Card.Body>
        </Card>
        <Footer />
      </Router>
      <Analytics />
    </div>
  );
}

export default App;
