import { Row, Col, Image } from "react-bootstrap";
import "../App.css";
import { InstagramOutlined, TikTokOutlined, TwitterOutlined } from "@ant-design/icons";

const Footer = () => {
  return (
    <footer className="text-center p-3 bg-MLColor5 text-secondary">
      <div className="container">
        <Row>
          <Col>
            <a
              href="https://twitter.com/MilkLoveTH"
              className="text-decoration-none text-secondary d-flex justify-content-center align-items-center"
              target={"_blank"}
              rel="noreferrer"
            >
              <Image
                alt="MilkLove Thailand logo"
                src="/logo/muvmuv-front.png"
                width="30"
                height="30"
                className="d-inline-block mb-1"
              />
              <Image
                alt="MilkLove Thailand logo"
                src="/logo/logo_name_v2_2.png"
                height="50"
                className="d-inline-block mx-2"
              />
              <Image
                alt="MilkLove Thailand logo"
                src="/logo/muvmuv-back.png"
                width="30"
                height="30"
                className="d-inline-block mb-1"
              />
            </a>
            <p className="mt-2">Copyright &copy; MilkLove Thailand 2024</p>
            <a
              href="https://twitter.com/MilkLoveTH"
              target={"_blank"}
              rel="noreferrer"
              className="mx-2"
            >
              <TwitterOutlined style={{ color: "orange", fontSize: "25px" }} />
            </a>
            <a
              href="https://www.instagram.com/milklove.th/"
              target={"_blank"}
              rel="noreferrer"
              className="mx-2"
            >
              <InstagramOutlined style={{ color: "orange", fontSize: "25px" }} />
            </a>
            <a
              href="https://www.tiktok.com/@milklove.th"
              target={"_blank"}
              rel="noreferrer"
              className="mx-2"
            >
              <TikTokOutlined style={{ color: "orange", fontSize: "25px" }} />
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
