import { Carousel, Row, Col } from "react-bootstrap";
import NavbarMenu from "./navbarMenu";
import "../App.css";

const Header = () => {
  return (
    <>
      <div>
        <Row>
          <Col className="lg-8 m-auto">
            <Carousel fade>
              <Carousel.Item interval={"5000"}>
                <img
                  className="d-block img-fluid"
                  src="/banner/milkloveth-banner-v1.jpg"
                  alt="MilkLove Thailand banner"
                />
              </Carousel.Item>
              <Carousel.Item interval={"5000"}>
                <img
                  className="d-block img-fluid"
                  src="/banner/milkloveth-banner-v2.jpg"
                  alt="MilkLove Thailand banner"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </div>
      <NavbarMenu />
    </>
  );
};

export default Header;
