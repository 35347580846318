import { ConfigProvider, FloatButton } from "antd";
import {
  MessageOutlined,
  TwitterOutlined,
  InstagramOutlined,
  TikTokOutlined,
} from "@ant-design/icons";

const SNSButtonGroup = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: "palevioletred",
        },
      }}
    >
      <FloatButton.BackTop
        style={{
          right: 29 + 50,
        }}
      />
      <FloatButton.Group
        trigger="click"
        style={{
          right: 29,
        }}
        icon={<MessageOutlined />}
      >
        <FloatButton
          icon={<TwitterOutlined />}
          tooltip={<div>X : MilkLoveTH</div>}
          href="https://twitter.com/MilkLoveTH"
          target={"_blank"}
          rel="noreferrer"
        />
        <FloatButton
          icon={<InstagramOutlined />}
          tooltip={<div>IG : milklove.th</div>}
          href="https://www.instagram.com/milklove.th/"
          target={"_blank"}
          rel="noreferrer"
        />
        <FloatButton
          icon={<TikTokOutlined />}
          tooltip={<div>TikTok : @milklove.th</div>}
          href="https://www.tiktok.com/@milklove.th"
          target={"_blank"}
          rel="noreferrer"
        />
      </FloatButton.Group>
    </ConfigProvider>
  );
};

export default SNSButtonGroup;
