/* eslint-disable array-callback-return */
import "./breadcrumb.css";
import { Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Profile",
    path: "profile",
  },
  {
    title: "Milk's Profile",
    path: "profile-milk",
  },
  {
    title: "Love's Profile",
    path: "profile-love",
  },
  {
    title: "Discography",
    path: "discography",
  },
  {
    title: "Schedule",
    path: "schedule",
  },
  {
    title: "Media",
    path: "media",
  },
  {
    title: "Announcement",
    path: "announcement",
  },
  {
    title: "Interview",
    path: "interview",
  },
];

const BreadcrumbList = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const isHome = pathnames.length === 0;
  let breadcrumbCurrent = "";
  let breadcrumbPath = "";
  let isLast = false;
  return !isHome ? (
    <Card className="card-breadcrumb container my-3 py-2">
      <div className="breadcrumbs text-dark ms-2">
        <span>
          <Link
            className="link-secondary link-offset-3 link-opacity-75 link-opacity-100-hover link-underline-opacity-25 link-underline-opacity-100-hover"
            to="/"
          >
            Home
          </Link>
        </span>
        {pathnames.map((name, index) => {
          items.every((item) => {
            breadcrumbCurrent = "";
            if (name === item.path) {
              breadcrumbCurrent = item.title;
              breadcrumbPath += `/${item.path}`;
              isLast = index === pathnames.length - 1;
              return false; // = break for loop
            }
            return true; // continue for loop
          });
          // In case, out of item lists
          if (breadcrumbCurrent === "") {
            breadcrumbCurrent = name;
            isLast = index === pathnames.length - 1;
          }

          return isLast ? (
            <span key={breadcrumbCurrent} style={{ color: "orange" }}>
              {" > "} <b>{breadcrumbCurrent}</b>
            </span>
          ) : (
            <span key={breadcrumbCurrent}>
              {" > "}
              <Link
                className="link-secondary link-offset-3 link-opacity-75 link-opacity-100-hover link-underline-opacity-25 link-underline-opacity-100-hover"
                to={breadcrumbPath}
              >
                {breadcrumbCurrent}
              </Link>
            </span>
          );
        })}
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default BreadcrumbList;
