export const mainMenu = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Profile",
    subMenu: [
      {
        title: "มิ้ลค์ (Milk) - พรรษา วอสเบียน",
        path: "/profile/profile-milk",
      },
      {
        title: "เลิฟ (Love) - ภัทรานิษฐ์ ลิ้มปติยากร",
        path: "/profile/profile-love",
      },
    ],
  },
  {
    title: "Discography",
    path: "/discography",
  },
  // {
  //   title: "Gallery",
  //   path: "/gallery",
  // },
  {
    title: "Schedule",
    path: "/schedule",
  },
  {
    title: "Media",
    path: "/media",
  },
  {
    title: "Announce/Interview",
    subMenu: [
      {
        title: "Announcement",
        path: "/announcement",
      },
      {
        title: "Interview",
        path: "/interview",
      },
    ],
  },
];
