import {
  Nav,
  Navbar,
  NavDropdown,
  NavLink,
  Container,
  Image,
} from "react-bootstrap";
import { mainMenu as menuLinks } from "./navbarLinks";
// import { snsLinks } from "../data/snsLists";
import { useLocation } from "react-router-dom";
import "../App.css";

const NavbarMenu = () => {
  const location = useLocation();

  const MenuItem = ({ title, path, subMenu, id }) => {
    if (subMenu) {
      return (
        <NavDropdown title={title} id={`nav-dropdown-${id}`}>
          {subMenu.map((item, index) => (
            <DropdownItem {...item} key={index} />
          ))}
        </NavDropdown>
      );
    }
    return <NavLink href={path}>{title}</NavLink>;
  };

  const DropdownItem = ({ title, path, divider }) => {
    if (divider) {
      return <NavDropdown.Divider />;
    }

    return (
      <NavDropdown.Item href={path}>
        <small>{title}</small>
      </NavDropdown.Item>
    );
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="MLColor5"
        variant="light"
        className="sticky-top"
      >
        <Container>
          <Navbar.Brand href="/" className="text-monospace">
            {/* for device size >= md (md, lg, xl, xxl) */}
            <div className="d-none d-md-block">
              <Image
                roundedCircle
                alt="MilkLove Thailand logo"
                src="/logo/ml_th.jpg"
                height="50"
                className="d-inline-block me-2"
              />
              <Image
                alt="MilkLove Thailand name"
                src="/logo/logo_name_v2.png"
                height="50"
                className="d-inline-block"
              />
            </div>
            {/* for device size < md (xs, sm) */}
            <div className="d-md-none">
              <Image
                roundedCircle
                alt="MilkLove Thailand logo"
                src="/logo/ml_th.jpg"
                height="40"
                className="d-inline-block me-2"
              />
              <Image
                alt="MilkLove Thailand name"
                src="/logo/logo_name_v2.png"
                height="32"
                className="d-inline-block"
              />
            </div>
            {/* MilkLove Thailand */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
            <Nav className="ms-auto" activeKey={location.pathname}>
              {menuLinks.map((item, index) => (
                <MenuItem {...item} key={index} />
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarMenu;
